import axios from "axios";
import handleApiError from "@/services/handleApiError";

const BASE_URL = '/api/file-tree';
export default {
    get(parent_id = null) {
        return axios.get(BASE_URL, {params: {parent_id: parent_id}})
            .then(resp => resp.data)
            .catch(handleApiError);
    },
    view(id) {
        return axios.get(`${BASE_URL}/view`, {params: {id: id}})
            .then(resp => resp.data).catch(handleApiError);
    },
    getFileTree(id) {
        return axios.get(`${BASE_URL}/get-file-tree`, {params: {id: id}})
            .then(resp => resp.data)
            .catch(handleApiError);
    },
}
