<template>
    <section class="base">
        <div class="base-wrapper">
            <CommonBlock
                title="Нормативна база"
                :visible-back-btn="true"
                :breadcrumbs="breadcrumbs"
                :cancelBackBtnEventPassed="true"
                @clickBack="clickBack"
            >
                <p class="base-text">
                    База документів, шаблонів заяв та звернень, законодавчих актів
                    необхідних для діяльності депутатів місцевих рад усіх рівнів.
                </p>
            </CommonBlock>
            <div class="bg">
                <div class="base-tree" :class="{'view-open': Object.keys(view).length}">
                    <div class="base-tree_top">
                        <div class="base-tree_header">
                            <h4 class="base-tree_title">Дерево файлів</h4>
                        </div>
                        <div class="accordion" v-if="items.length">
                            <FolderItem
                                v-for="item in items"
                                :key="item.id"
                                @openDir="openDir"
                                @closeDir="closeDir"
                                @openView="openView"
                                :name="item.name"
                                :lazy_items="lazy_items"
                                :id="+item.id"
                                :type="+item.type"
                                :opened="item.opened"
                                :active="item.active"
                                :file_extension="item.file_extension || undefined"
                                :childrenCount="+item.childrenCount"
                                :children="item.children"
                            />
                        </div>
                        <div v-else-if="loading" class="preloader">Завантаження...</div>
                        <div v-else class="tree-empty">Даних не знайдено</div>
                    </div>
                </div>
                <transition>
                    <DocumentInfoItem
                        v-if="Object.keys(view).length"
                        :preloader="document_preloader"
                        :name="view.name"
                        :id="+view.id"
                        :directory="view.directory"
                        :preview="view.preview"
                        :text="view.description"
                        :file_extension="view.file_extension"
                        :file_size="+view.file_size"
                        :download="view.download"
                        :view_file="view.view_file"
                    />
                </transition>
            </div>
        </div>
    </section>
</template>

<script>
import FolderItem from "../components/Documents/FolderItem";
import DocumentInfoItem from "../components/DocumentInfoItem";
import FileTreeService from "@/services/FileTreeService";
import CommonBlock from "../components/CommonBlock";

let opened_file = {};
export default {
    components: {
        FolderItem,
        CommonBlock,
        DocumentInfoItem,
    },
    data() {
        return {
            loading: false,
            document_preloader: false,
            items: [],
            opened_dir_key: 0,
            lazy_items: true,
            breadcrumbs: [
                {id: 1, name: 'Нормативна база'}
            ],
            view: {}
        };
    },
    created() {
        if (this.$route.params.id) {
            this.lazy_items = false;
            this.setStructure(this.$route.params.id, true);
        } else {
            this.addStructure();
        }
    },
    methods: {
        async setStructure(id, lazy = false) {
            let data = await FileTreeService.getFileTree(id);
            this.items = this.findChildren(data.data, null, id)['items'];

            if (lazy) {
                await this.openView(id);
            }
        },
        findChildren(data, parent_id = null, id) {
            let items = [];
            let opened = false;
            for (let key in data) {
                if (!data.hasOwnProperty(key) || data[key]['parent_id'] !== parent_id) {
                    continue;
                }

                let item = Object.assign({}, data[key]);
                item['active'] = false;
                item['opened'] = false;

                if (item['id'] === id) {
                    opened = true;
                    item['active'] = true;
                    item['opened'] = false;
                }

                if (+data[key]['childrenCount'] > 0) {
                    delete data[key];
                    let res = this.findChildren(data, item['id'], id);
                    if(!opened) {
                        opened = res['opened'];
                        item['opened'] = res['opened'];
                    }

                    item['children'] = res['items'];
                    items.push(item);
                } else {
                    item['children'] = [];
                    delete data[key];
                    items.push(item);
                }
            }

            setTimeout(() => {
                this.lazy_items = true;
            });

            return {
                items: items,
                opened: opened
            };
        },
        clickBack() {
            if (Object.keys(this.view).length) {
                this.view = {};
            } else {
                this.$router.go(-1);
            }
        },
        openDir(id) {
            this.addStructure(id);
        },
        findOpenedFileByParentId(id) {
            if (this.getItemById(id) && this.getItemById(id)['children'].length) {
                let item1 = null;
                let parent = this.getItemById(id);

                for(let key in parent.children) {
                    if(!parent.children.hasOwnProperty(key)) {
                        continue;
                    }

                    let item = parent.children[key];
                    if (item.active) {
                        item1 = item;
                        break;
                    }

                    if (item.children && item.children.length && !item1) {
                        item1 = this.findOpenedFileByParentId(item.id);
                    }
                }

                if (item1) {
                    return item1;
                }
            }
        },
        closeDir(id) {
            let item = this.findOpenedFileByParentId(id);
            if(item && opened_file[item.id]) {
                this.view = {};
                delete opened_file[item.id];
                item.active = false;
                return false;
            }
        },
        async openView(id) {
            let item = this.getItemById(id);
            if (opened_file[item.id]) {
                this.view = {};
                delete opened_file[item.id];
                item.active = false;
                return false;
            } else {
                Object.values(opened_file).forEach(item => item.active = false);
                opened_file = {};
                opened_file[item.id] = item;
                item.active = true;
            }

            this.document_preloader = true; // display DocumentInfoItem preloader
            let data = await FileTreeService.view(item.id);
            this.document_preloader = false;
            this.view = data.data;

            let link = this.$router.resolve({name: 'base', params: {id: id}});
            history.pushState(null, null, link.href);
        },
        getItemById(id, items = this.items) {
            let findItem = {};
            for (let key in items) {
                if (!items.hasOwnProperty(key)) {
                    continue;
                }

                if (+items[key].id === +id) {
                    findItem = items[key];
                    break;
                }

                if (items[key].children.length) {
                    findItem = this.getItemById(id, items[key].children);
                    if (Object.keys(findItem).length) {
                        break;
                    }
                }
            }

            return findItem;
        },
        async addStructure(id = null) {
            let parent_id = null;
            let item = this.getItemById(id);

            if (id && Object.keys(item).length) {
                parent_id = item.id;
            } else {
                this.loading = true;
            }

            let data = await FileTreeService.get(parent_id);

            if (Object.keys(item).length) {
                item['children'] = data.data.map(item1 => {
                    item1['children'] = [];
                    item1['active'] = false;
                    return item1;
                });
            } else {
                this.items = data.data.map((item, index) => {
                    item['opened'] = index === this.opened_dir_key;
                    item['children'] = [];
                    item['active'] = false;
                    return item;
                });

                this.loading = false;
            }
        }
    }
};
</script>

<style>
@import "../assets/css/file-icon-square-o.css";
</style>

<style scoped lang="less">
.v-leave-active, .v-enter-active {
    @media all and (min-width: 950px) {
        transition: margin-right .52s linear;
    }
    @media all and (max-width: 950px) {
        transition: transform .3s linear;
    }
}

.v-enter, .v-leave, .v-leave-to {
    @media all and (min-width: 950px) {
        // margin-right: -40%;
    }
    @media all and (max-width: 950px) {
        transform: translate(100%);
    }
}

.base {
    overflow-x: hidden;
    height: 100vh;
    position: relative;

    .bg {
        display: flex;
        width: 100%;
        overflow: hidden;
        @media all and(max-width: 1300px) {
            position: relative;
        }
    }

    @media all and(max-width: 950px) {
        height: auto;
        .bg {
            overflow: visible;
            width: 100%;
            position: relative;
            flex: 1 1 auto;
        }
    }

    .preloader {
        text-align: center;
    }

    .tree-empty {
        text-align: center;
    }

    &-wrapper {
        display: flex;
        @media all and(max-width: 950px) {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
        }
    }

    &-text {
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        color: #fff;
        margin-bottom: 40px;
    }

    &-tree {
        &.view-open {
            min-width: 50%;
        }

        min-width: 100%;
        transition: min-width .5s linear;
        height: 100vh;
        background: #fff;
        overflow-y: auto;
        scrollbar-color: #1a2744 #ffc900;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track {
            background: #ffc900;
        }

        &::-webkit-scrollbar-thumb {
            background: #1a2744;
            border-radius: 0;
        }

        &_top {
            padding: 80px 80px 0 40px;
        }

        @media all and(max-width: 950px) {
            width: 100% !important;
            height: auto !important;
            &_top {
                padding: 0 !important;
            }
        }

        &_header {
            position: relative;
            padding-bottom: 45px;
            margin-bottom: 20px;
            padding-left: 40px;
            @media all and(max-width: 950px) {
                display: none;
            }

            &:after {
                position: absolute;
                content: "";
                width: calc(100% - 40px);
                height: 1px;
                bottom: 0;
                right: 0;
                background: #000;
                opacity: 0.15;
            }
        }

        &_title {
            font-weight: bold;
            font-size: 32px;
            line-height: 40px;
            color: #1a2744;
            margin-bottom: 10px;
            position: relative;

            &::before {
                position: absolute;
                content: "";
                top: 4px;
                left: -40px;
                width: 30px;
                height: 25px;
                background: url(../assets/images/golder-big.svg) no-repeat center;
                background-size: contain;
            }
        }
    }
}

@media screen and (max-width: 1920px) {
    .base {
        &-tree {
            height: 100vh;
        }
    }
}

@media screen and (max-width: 1440px) {
    .base-title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .base-text {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 25px;
    }

    .tree_tree {
        overflow-y: auto;
    }

    .base-tree_top {
        padding: 40px 30px 40px 40px;
    }

    .base-tree_title {
        font-size: 22px;
        line-height: 35px;
    }
}
</style>


