<template>
    <div>
        <div class="accordion-item" :class="{folder: isDir, file: !isDir, active: active}">
            <header :class="{active: visibleChildren}" @click="accordion">
                <div class="folder-ind" v-if="isDir" :class="{visibility: childrenCount > 0}"></div>
                <span v-if="!isDir">
                    <span :class="`fiv-sqo fiv-icon-${file_extension}`"></span>
                    {{ name }}
                </span>
                <span v-else>{{ name }}</span>
            </header>
            <div class="children" v-if="visibleChildren && children.length">
                <FolderItem
                    v-for="child in children"
                    :key="child.id"
                    @openView="openView"
                    @openDir="openDir"
                    @closeDir="closeDir"
                    :name="child.name"
                    :id="+child.id"
                    :lazy_items="lazy_items"
                    :type="+child.type"
                    :active="child.active"
                    :opened="child.opened"
                    :file_extension="child.file_extension || undefined"
                    :childrenCount="+child.childrenCount"
                    :children="child.children"
                />
            </div>
            <div v-else-if="visibleChildren && !children.length" class="preloader">
                Loading...
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "FolderItem",
    props: {
        id: {
            type: Number,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        type: {
            type: Number,
            required: true,
            validator(value) {
                return [0, 1].indexOf(+value) !== -1;
            }
        },
        file_extension: {
            type: String,
            required: false,
            default: undefined
        },
        lazy_items: {
            type: Boolean,
            required: false,
            default: true
        },
        childrenCount: {
            type: Number,
            required: false,
            default: 0
        },
        active: {
            type: Boolean,
            required: true
        },
        opened: {
            type: Boolean,
            required: false,
            default: false
        },
        children: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            visibleChildren: false
        };
    },
    created() {
        if(this.opened && this.isDir) {
            if(!this.lazy_items) {
                this.visibleChildren = !this.visibleChildren;
            }else{
                this.accordion();
            }
        }
    },
    computed: {
        isDir() {
            return this.type === 0;
        }
    },
    methods: {
        openDir(id) {
            this.$emit('openDir', id);
        },
        openView(id) {
            this.$emit('openView', id);
        },
        closeDir(id) {
            this.$emit('closeDir', id);
        },
        accordion() {
            if(this.isDir && this.childrenCount > 0) {
                this.visibleChildren = !this.visibleChildren;
                if(this.visibleChildren) {
                    this.openDir(this.id);
                }else{
                    this.closeDir(this.id);
                }
            }else if(!this.isDir){
                this.openView(this.id);
            }
        },
    },
};
</script>

<style scoped lang="less">
.accordion-item {
    position: relative;
    .preloader{
        text-align: center;
    }
    &.active{
        header{
            background-color: #f1f1f1;
        }
    }
    header {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.098px;
        color: #1a2744;
        cursor: pointer;
        padding: 5px 5px 5px 35px;
        position: relative;
        transition: background-color .15s linear;
        word-break: break-word;
        
        &:hover{
            background-color: #f1f1f1;
        }
        &:after, &:before{
            content: "";
        }
    }
    &.folder{
        header{
            font-weight: 700;
            display: flex;
            padding-left: 4px;
            @media all and(max-width: 950px) {
                padding: 9px 20px;
            }
            *::selection{
                background-color: transparent;
            }
            &:before {
                top: 4px;
                position: relative;
                width: 16px;
                min-width: 16px;
                height: 14px;
                background: url('../../assets/images/folder.svg') no-repeat center;
                background-size: contain;
                margin-right: 14px;
                @media all and(max-width: 950px) {
                    order: 1;
                    flex: 1 1 auto;
                    background-position: right center;
                    margin-right: 0;
                    margin-left: 14px;
                }
            }
            .folder-ind{
                width: 17px;
                min-width: 17px;
                height: 17px;
                position: relative;
                border-radius: 50%;
                background-color: #f5f5f5;
                padding: 3px;
                top: 2px;
                box-sizing: border-box;
                order: -1;
                margin-right: 20px;
                &:not(.visibility){
                    visibility: hidden;
                    @media all and(max-width: 950px) {
                        display: none;
                    }
                }
                &:after, &:before{
                    content: '';
                    top: 50%;
                    left: 50%;
                    margin-left: -3.5px;
                    margin-top: -.5px;
                    display: block;
                    position: absolute;
                    height: 1px;
                    background-color: #1A2744;
                    width: 7px;
                    border-radius: 30%;
                    transition: transform .15s linear;
                }

            }
            &:not(&.active){
                .folder-ind{
                    &:after{
                        transform: rotate(90deg) scale(0.9);
                    }
                }
            }
        }
    }
    &.file{
        header{
            &:before{
                display: none;
            }
            .fiv-sqo{
                margin-right: 16px;
            }
        }
    }
    &:not(.folder){
        header{
            font-weight: 400;
            border-bottom: 1px solid #f1f1f1;
            padding: 5px 5px 5px 2px;
            &:after{
                display: none;
            }
            display: flex;
            justify-content: space-between;
            @media all and(max-width: 950px) {
                font-size: 13px;
                line-height: 1.54;
                padding: 9px 20px;
                span{
                    width: 100%;
                    display: block;
                }
                & > span{
                    display: flex;
                    align-items: center;
                    .fiv-sqo{
                        order: 1;
                        flex: 1 1 auto;
                        min-width: 16px;
                        background-position: right center;
                        width: 16px;
                        height: 16px;
                        margin-left: 14px;
                        margin-right: 0;
                    }
                }
            }
            .view {
                margin-right: 11px;
            }
        }
    }
    .children {
        padding-left: 30px;
        @media all and(max-width: 950px) {
            padding-left: 0;
        }
        header{
            border-bottom: 1px solid #f1f1f1;
            padding-left: 37px;
            @media all and(max-width: 950px) {
                padding-left: 20px;
            }
        }
    }
}

.accordion-item_actions {
    display: flex;
    .view {
        margin-right: 11px;
    }
}
</style>
